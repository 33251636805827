import Image from "next/image";
import cn from "clsx";
import { Text } from "@components/ui";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { SliderSlick } from "@components/commun";
import { Settings } from "react-slick";
import style from "./SliderProducts.module.scss";

export type SliderProductsProps =
  SliceComponentProps<Content.SliderProductsSlice>;

const SliderProducts = ({ slice }: SliderProductsProps): JSX.Element => {
  const slidersettings: Settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.root}
    >
      <div className={style.products}>
        <SliderSlick settings={slidersettings}>
          {slice.items.map((item, index) => {
            const [type, label] =
              item.type !== "none"
                ? item.type.split(":").map((typeItem) => typeItem.trim())
                : [null, null];

            return (
              <div className={style.slide} key={index}>
                <div>
                  <Text variant="span" className={style.title}>
                    {item.title}
                  </Text>
                  {type !== null && (
                    <div className={cn(style.type, style[type])}>{label}</div>
                  )}
                  <div className={style.thumbnail}>
                    <div>
                      <Image
                        src={item.feature.url ?? ""}
                        fill={true}
                        alt=""
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </SliderSlick>
      </div>
    </section>
  );
};

export default SliderProducts;
